.storesCarousel {
  h1 {
    font-size: 15px;
  }
  ion-card {
    height: 164px;
    width: 165px;
    white-space: break-spaces;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    cursor: pointer;

    .imgContainer {
      height: 85px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .storeName {
      background-color: var(--primary-label-bg-color);
      height: 29px;
      width: 100%;
      max-width: 165px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 10px;
      ion-label {
        max-width: 145px;
        margin: 0;
        font-size: 12px;
        font-weight: 600;
        color: var(--primary-label-text-color);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    ion-button {
      height: 29px;
      font-size: 10px;
      font-weight: 600;
      .commission {
        display: flex;
        align-items: center;
        span {
          padding-left: 3px;
          font-size: 15px;
        }
      }
    }
  }
  ion-item {
     --inner-padding-end:5px;
     ion-button {
       text-decoration: underline;
     }
   }
}

