.cashbackBalanceDetailModal {
  --width: 90%;
  --max-width: 500px;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);

  .closeIcon {
    margin-right: 10px;
    font-size: 20px;
    cursor: pointer;
  }
  .balanceCards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .noTransaction {
    align-items: center;
    height: 100%;
    display: flex;
    justify-content: center;
  }
}

.cashbackBalanceDetailModal::part(content) {
  right: auto;
  bottom: auto;
  height: 500px;
}

