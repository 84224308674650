.PriceCompare {
  ion-card {
    width: 100%;
    display: flex;
    padding-right: 10px;
    ion-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      .offer {
        .originalPrice {
          display: inline;
          text-decoration: line-through;
        }
        ion-label {
          display: inline;
        }
      }
    }
    .accordionTitle {
      max-width: 200px;
      padding-top: 38px;
      padding-left: 50px;
    }
    img {
      margin: 16px 10px;
    }
  }
  ion-accordion-group {
    ion-accordion {
      border: 1px solid #ccc !important;
      border-radius: 10px;
      margin-bottom: 20px;
      text-align: center;
      ion-item {
        --inner-padding-end: 0px;
        --box-shadow: none;
        ion-text {
          .variantsText {
            color: var(--ion-color-success);
            margin-right: 5px;
          }
        }
        ion-card {
          box-shadow: none;
          .price {
            margin-top: 8px;
          }
        }
      }
      div[slot="content"] {
        ion-card {
          border-radius: 0%;
          height: 65px;
          background-color: var(--blur-background);
          .itemTitleContainer {
            width: 30%;
            margin: 16px 10px;
          }
          ion-text {
            font-size: 12px;
          }
        }
      }
    }
  }
  .itemTitle {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 15px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 30px;
    max-height: 30px;
    color: black;
  }
  .cashbackText {
    color: var(--ion-color-primary);
    text-align: center;
  }
  .removeCashback {
    padding-top: 5px;
  }
  .variantsText {
    margin-top: 2px;
  }
  ion-button {
    width: 105px;
    height: 32px;
  }
}
@media screen and (min-width: 768px) {
  .price {
    min-width: 220px;
  }
}

.inlineContainer {
  display: flex;
  align-items: center;
}

.padt {
  padding-top: 1.5px;
}

.custom_item {
  display: flex;
  flex-direction: row;
}
