.header {
  ion-header {
    height: 70px;
  }
  ion-button {
    --background: transparent;
    --color: black;
    --box-shadow: none;
    height: 50px;
    margin: 0px;
  }
  ion-img {
    width: 100px;
    height: fit-content;
    padding-left: 11px;
  }
  .logo {
    cursor: pointer;
  }
  .searchBarContainer {
    width: 100%;
    flex: 1;
    padding: 0 40px 0px 40px;
    ion-searchbar {
      border-radius: 5px;
      --box-shadow: none;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
      padding: 3px;
    }
  }
  ion-button:hover {
    --color: var(--ion-color-primary);
  }
  .active {
    color: var(--ion-color-primary);
  }
  .underline {
    text-decoration: 2.5px solid underline;
    text-underline-offset: 11px;
  }
}
.popOver {
  --width: 200px;
}
