.logoContainer {
  z-index: 100 !important;
  position: absolute;
  background: white;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  img {
    width: fit-content;
    height: fit-content;
    border: 1px solid var(--header-logo-border-color);
    border-radius: 5px;
    padding:0 5px;
  }
}
.commissionContentContainer {
  height:100%;
  ion-grid {
    height: 100%;
    .expertTip{
      box-shadow: none;
      border: 1px solid var(--card-secondary-border-color);
      border-radius: 10px;
      margin-bottom: 30px;
      background: var(--card-secondary-bg-color);
    }
    .toolTipCard {
      box-sizing: border-box;
      position: absolute;
      bottom: -50px;
      left: 0;
      right: 0;
      border: 1px solid #e7e7e7;
      ion-item{
        --padding-start: 0;
        --padding-end: 0;
        --inner-padding-end: 0;
        ion-icon{
          margin:5px 5px 0px 0px ;
        }
      }
    }
    hr {
      display: block;
      height: 0;
      border-bottom: 1.5px solid #ececec;
    }
  }
  .contentContainer{
    flex: 6;
  }
  .earnUptoBtn{
    flex:1;
    ion-button {
      --border-radius: 5px;
      margin-bottom: 0px;
      --padding-start: 40px;
      --padding-end: 40px;
    }
  }
  .earnUptoClearBtn{
    flex:1;
    ion-button{
      --padding-start:15px;
      --padding-end:15px;
    }
  }
}
.footer {
  ion-button {
    height: 40px;
    border-top: 1px solid var(--header-logo-border-color);
    --border-radius: 0px !important;
    border-radius: 0px !important;
  }
}
.modal {
  --border-radius: 10px 10px 0px 0px;
  margin-bottom: 40px;
  --height: 80%;
  ion-header{
    --box-shadow: none;
    .filterHeader {
      border-bottom: 1px solid #ececec;
    }
  }
  ion-content {
    .detailsCard {
      z-index: 999;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px,  rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
      position: fixed;
      bottom: 0;
      width: 100%;
      border-radius: 10px 10px 0px 0px;
      ion-item {
        --min-height: fit-content;
        ion-label {
          padding: 5px 0px;
        }
      }
    }
    .toolTipModalCard {
      box-sizing: border-box;
      position: fixed;
      bottom: 90px;
      left: 0;
      right: 0;
      border: 1px solid #e7e7e7;
      ion-item{
        --padding-start: 0;
        --padding-end: 0;
        --inner-padding-end: 0;
        ion-icon{
          margin:5px 5px 0px 0px ;
        }
      }
      z-index: 99999;
    }
  }
}
.statusRow {
  width: 100%;
  position: relative;
  .status {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .dot {
      display: inline-block;
      border: 2px solid;
      border-radius: 25px;
      width: 2px;
      height: 2px;
      padding: 6px;
    }
    .vl {
      margin-top: 7.5px;
      border-top: 1px dashed black;
      width: 100%;
    }
  }
  .processDetails {
    p {
      max-width: 80px;
    }
  }
}

.nodata{
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top:100px;
  ion-img{
    width: 150px;
  }
  ion-text{
    line-height: 35px;
  }
  a{
    text-decoration: underline;
    color: var(--ion-color-primary);
  }
  width: 100%;
  height: 69%;
}