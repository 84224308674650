.nodata {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  ion-img {
    width: 150px;
  }
  width: 100%;
  height: calc(100vh - 390px);
  margin-bottom: 70px;
}
@media screen and (min-width: 576px) {
  .nodata {
    padding-top: 0px;
    height: calc(100vh - 290px);
  }
}
@media screen and (min-width: 768px) {
  .nodata {
    padding-top: 0px;
    height: calc(100vh - 180px);
  }
}
