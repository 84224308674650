.dealDetail {
  .btnContainer {
    width: 50%;
  }

  .dealDetailTitle {
    width: 50%;
    transform: translate(-50%);
  }

    .swiper {
      padding-bottom: 30px;

      ion-card {
        width: 100%;
        border-radius: 8px;
        height: 208px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    @media screen and (min-width: 768px) {
      .swiper {
        ion-card {
          height: 400px;
        }
      }
      .visitBtn {
        margin-top: 20px !important;
        width: 200px;
        font-size: 14px !important;
        --border-radius:10px;
        --box-shadow: none;
      }
    }

  ion-card-content,
  ion-toolbar {
    .offerPrice {
      font-size: 20px;
    }

    .originalPrice {
      text-decoration: line-through;
    }
  }

  ion-footer {
    ion-button{
      --border-radius:var( --secondary-button-border-radius) !important;
    }
  }
}

.content{
  .contentGrid{
    min-height: calc(100% - 60px);
  }
}
.noData{
  margin-top: 100px;
}