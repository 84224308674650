.footer{
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  span{
    font-size: 12px;
    font-weight: 500;
    margin-right: 5px;
  }
  ion-img{
    width: 64px;
  }
}