ion-grid {
  --ion-grid-width: 1200px;
}

.pad10 {
  padding: 10px;
}

.padb10 {
  padding-bottom: 10px;
}
.padr10 {
  padding-right: 10px;
}

.padl10 {
  padding-left: 10px;
}

.padt10 {
  padding-top: 10px;
}
.pad8 {
  padding: 8px;
}

.no-padding-bottom {
  padding-bottom: 0px;
}

.mart10 {
  margin-top: 10px !important;
}

.mart6 {
  margin-top: 6px;
}

.mart0 {
  margin-top: 0px;
}

.marb10 {
  margin-bottom: 10px;
}

.marb40 {
  margin-bottom: 40px;
}
.marr10 {
  margin-right: 10px;
}
.marl10 {
  margin-left: 10px;
}
.mart24 {
  margin-top: 24px;
}

.marl4 {
  margin-left: 4px;
}

.m-auto {
  margin: auto;
}

.no-margin-left {
  margin-left: 0px;
}

.no-margin-right {
  margin-right: 0px;
}

.no-margin-top {
  margin-top: 0px;
}

.font8 {
  font-size: 8px;
}

.font10 {
  font-size: 10px;
}

.font11 {
  font-size: 11px;
}

.font12 {
  font-size: 12px !important;
}

.font14 {
  font-size: 14px;
}

.font16 {
  font-size: 16px;
}
.font18 {
  font-size: 18px;
}

.font900 {
  font-weight: 900;
}

.font600 {
  font-weight: 600;
}
.font700 {
  font-weight: 700;
}
.font500 {
  font-weight: 500;
}

.font400 {
  font-weight: 400;
}
.w100 {
  width: 100%;
}
.lh-21 {
  line-height: 21px;
}

.scrollx {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.scroll-item {
  display: inline-block;
}

.no-scroll {
  --overflow: hidden;
}

.desc-text {
  color: var(--ion-color-step-550, #737373);
}

ion-back-button.eventsback {
  border: 1px solid #dededec2;
  border-radius: 8px;
  --color: #3880ff;
}

ion-button.eventsback {
  border: 1px solid #dededec2;
  border-radius: 8px;

  ion-icon {
    color: #3880ff;
  }
}

.d-contents {
  display: contents;
}

ion-col.no-left-spacing {
  margin-left: -10px;
}

ion-col.no-right-spacing {
  margin-right: -10px;
}

ion-segment {
  height: 45px;
}

.store-img {
  width: 80%;
}

.grey-border {
  border: 1px solid #dedede;
}

.border-r4 {
  border-radius: 4px;
}

.border-r8 {
  border-radius: 8px;
}

.line-2-clip {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-1-clip {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-3-clip {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-shadow {
  box-shadow: none !important;
}

.d-block {
  display: block;
}
.d-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}

.header-logo {
  ion-img {
    width: 120px;
    margin: auto;
  }
}

.no-opacity {
  opacity: 0;
}

ion-button {
  text-transform: capitalize;
}

ion-card {
  border-radius: 10px;
}

.page-container {
  margin: 0px 12px 0px 12px;
}

a {
  font-size: 100%;
  text-decoration: none;
  display: flex;
  color: var(--ion-color-dark);
}

.scrollableDesign {
  width: 100%;
  height: 30px;
  background-color: var(--header-primary-bg-color);

  .scrollable {
    width: 100%;
    background-color: var(--header-secondary-bg-color);
    border-top: 2rem solid var(--header-secondary-bg-color);
    border-radius: 2rem 2rem 0rem 0rem;
  }
}

* {
  font-family: "Sora", sans-serif;
}

.custom-sheet {
  .action-sheet-group.sc-ion-action-sheet-md:not(.action-sheet-group-cancel) {
    border-radius: 10px 10px 0px 0px;

    .action-sheet-title.sc-ion-action-sheet-md {
      font-weight: 500;
      background: var(--action-sheet-bg-color);
      --color: var(--action-sheet-text-color);
      font-size: 14px;
    }

    .action-sheet-selected {
      color: var(--action-sheet-active-color);
    }

    .action-sheet-button.sc-ion-action-sheet-md {
      font-size: 12px;
      height: 40px;
      font-weight: 500;
      border-bottom: 1px solid #ececec;
      --color: var(--action-sheet-text-color);
    }
  }
}

.text-len {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.shareButton {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 20px;
  height: 20px;
  padding: 5px;
  border: 1px solid rgb(197, 197, 197);
  border-radius: 50%;
  background-color: white;
}

//  Color Theme Variables

/** Primary Color**/
.primary-title-color {
  color: var(--primary-title-color);
}
.primary-subTitle-color {
  color: var(--primary-subTitle-color);
}
.primary-desc-color {
  color: var(--primary-desc-color);
}
/** Default colors **/

.danger {
  color: var(--ion-color-danger);
}
.warning {
  color: var(--ion-color-warning);
}
.success {
  color: var(--ion-color-success);
}
.dark {
  color: var(--ion-color-dark);
}
.light {
  color: var(--ion-color-light);
}
.text-color-grey {
  color: var(--ion-color-grey);
}

/** Header Color **/

.header-primary-bg-color {
  --background: var(--header-primary-bg-color);
}
.header-text-color {
  color: var(--header-text-color);
}
/** Button Color  **/

.primary-btn {
  --background: var(--primary-button-bg-color) !important;
  --color: var(--primary-button-text-color);
  --box-shadow: none;
  --border-radius: var(--primary-button-border-radius);
  --background-activated: var(--primary-button-active-color) !important;
  --background-activated-opacity: 0.5;
  --background-focused: var(--primary-button-active-color) !important;
  --background-focused-opacity: 0.5;
  --background-hover: var(--primary-button-active-color) !important;
  --background-hover-opacity: 0.5;
  border-radius: var(--primary-button-border-radius);
}
.secondary-btn {
  --background: var(--secondary-button-bg-color) !important;
  --color: var(--secondary-button-text-color);
  --box-shadow: var(--secondary-button-box-shadow) !important;
  --border-radius: var(--secondary-button-border-radius);
  --background-activated: var(--secondary-button-active-color) !important;
  --background-activated-opacity: 0.5;
  --background-focused: var(--secondary-button-active-color) !important;
  --background-focused-opacity: 0.5;
  --background-hover: var(--secondary-button-active-color) !important;
  --background-hover-opacity: 0.5;
  border-radius: var(--secondary-button-border-radius);
}
.tertiary-btn {
  --background: var(--tertiary-button-bg-color);
  --color: var(--tertiary-button-text-color);
  --box-shadow: none;
}

.white-btn {
  --background: var(--white-button-bg-color);
  --color: var(--white-button-text-color, #000000);
  --box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  --border-radius: 10px;
  --background-activated: var(--white-button-active-color);
  --background-activated-opacity: 0.5;
  --background-focused: var(--white-button-active-color);
  --background-focused-opacity: 0.5;
  --background-hover: var(--white-button-active-color);
  --background-hover-opacity: 0.5;
  --rippler-color: var(--white-button-bg-color);
  border-radius: 10px;
}

.primary-border-btn {
  --background: var(--primary-border-button-bg-color);
  --color: var(--primary-border-button-text-color);
  --box-shadow: var(--primary-border-button-box-shadow);
  --border-radius: var(--primary-border-button-border-radius);
  --background-activated: var(--primary-border-button-active-color);
  --background-activated-opacity: 0.5;
  --background-focused: var(--primary-border-button-active-color);
  --background-focused-opacity: 0.5;
  --background-hover: var(--primary-border-button-active-color);
  --background-hover-opacity: 0.5;
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  margin: 0;
  padding: 1px;
  border: var(--primary-border-button-border-size) solid
    var(--primary-border-button-border-color);
  border-radius: var(--primary-border-button-border-radius);
}

.clear-btn {
  --background: var(--clear-button-bg-color);
  --color: var(--clear-button-text-color);
  --box-shadow: none;
  --border-radius: 5px;
  --background-activated: transparent;
  --background-activated-opacity: 0;
  --background-focused: transparent;
  --background-focused-opacity: 0;
  --background-hover: transparent;
  --background-hover-opacity: 0;
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  margin: 0;
  height: fit-content;
}

.disable-btn {
  --background: #d9d9d9;
  --color: #606060;
  --border-radius: 10px;
  --box-shadow: none;
}
.special-btn {
  color: var(--special-button-text-color);
}

/** Card **/

.card-white-bg-color {
  background: var(--card-white-bg-color);
}
.card-grey-bg-color {
  background: var(--card-grey-bg-color);
}
.card-secondary-bg-color {
  background: var(--card-secondary-bg-color);
}

.card-title-color {
  color: var(--card-title-text-color);
}
.card-subTitle-color {
  color: var(--card-subTitle-text-color);
}
.card-desc-text-color {
  color: var(--card-desc-text-color);
}
.card-danger-text-color {
  color: var(--card-danger-text-color);
}
.card-grey-text-color {
  color: var(--card-grey-text-color);
}
.card-special-text-color {
  color: var(--card-special-text-color);
}
.card-primary-text-color {
  color: var(--card-primary-text-color);
}

.card-primary-border-color {
  color: var(--card-primary-border-color);
}
.card-secondary-border-color {
  color: var(--card-secondary-border-color);
}

/** Icon **/
.icon-primary-color {
  color: var(--icon-primary-color);
}
.icon-secondary-color {
  color: var(--icon-secondary-color);
}
.icon-danger-color {
  color: var(--icon-danger-color);
}
.icon-special-color {
  color: var(--icon-special-color);
}
.icon-dark-color {
  color: #000000;
}

/** Checkbox **/

.checkbox {
  --size: 15px;
  --background-checked: var(--checkbox-bg-color);
  --border-color: var(--checkbox-border-color);
  --border-color-checked: var(--checkbox-border-color-checked);
}

/** Item **/

.item-text-color {
  color: var(--item-text-color);
}

.primary-border-color {
  color: var(--ion-color-primary);
}

/** Accordion **/

.accordion-white-bg-color {
  background: var(--accordion-white-bg-color);
}
.accordion-grey-bg-color {
  background: var(--accordion-grey-bg-color);
}
.accordion-title-color {
  color: var(--accordion-title-text-color);
}
.accordion-subTitle-color {
  color: var(--accordion-subTitle-text-color);
}
.accordion-desc-text-color {
  color: var(--accordion-desc-text-color);
}
.accordion-desc-text-color {
  color: var(--accordion-desc-text-color);
}
.accordion-danger-text-color {
  color: var(--accordion-danger-text-color);
}
.accordion-grey-text-color {
  color: var(--accordion-grey-text-color);
}
.accordion-special-text-color {
  color: var(--accordion-special-text-color);
}



@media screen and (min-width: 768px) {
  .card_pad8 {
    padding: 8px;
  }
  .card_mar8 {
    margin: 8px;
  }
}
@media screen and (max-width: 768px) {
  .card_pad5 {
    padding: 5px;
  }
  .card_mar5 {
    margin: 5px;
  }
}

.swiper-button-next,
.swiper-button-prev {
  color: white;
  background-color: var(--ion-color-primary);
  top: 45%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  right: 15px;
  border-radius: 10px;
}
.swiper-button-prev {
  left: 15px;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 20px;
}

@media screen and (min-width: 768px) {
  .ion-accordion-toggle-icon {
    position: absolute;
    left: 67%;
    bottom: 19px;
    font-size: 12px;
    color: black;
  }
}
@media screen and (max-width: 768px) {
  .ion-accordion-toggle-icon {
    position: absolute;
    left: 52%;
    bottom: 4px;
    font-size: 12px;
    color: black;
  }
}