.filterContainer {
  background: white;
  padding: 15px 20px 250px 15px;
  height: 100%;
  position: fixed;
  overflow: auto;
  width: 210px;
  .title {
    padding-bottom: 10px;
    border-bottom: 0.1px solid #b3b3b3;
  }
  ion-list {
    max-height: 140px;
    overflow: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    ion-item {
      --min-height: 0px;
    }
  }
  ion-checkbox {
    margin-left: 10px;
  }
  ion-checkbox::part(container) {
    border-radius: 2px;
  }
  ion-button {
    --background: transparent;
    --color: #000000;
    --box-shadow: none;
    font-size: 12px;
    height: 20px;
  }
  ion-button:hover {
    color: var(--ion-color-primary);
  }
  .padl6 {
    padding-left: 6px;
  }
}
