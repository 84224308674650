.cashback, .details {
  display: flex;
  align-items: center;
  justify-content: center;

  p{
    font-size: 10px;
    font-weight: 500;
    margin-left: 10px;
  }
  .commission{
    margin-bottom: 0;
  }
  .noCommission{
    margin-bottom: 0;
  }
}

.details ion-icon {
  font-size: 20px;
}

.details p {
  font-size: 12px;
}
