.productCategory {
  ion-item {
    --inner-padding-end:5px;
    ion-button {
      text-decoration: underline;
    }
  }

  ion-title {
    font-size: 15px;
  }
  .noPadding{
    --inner-padding-end: 0px;
  }
}