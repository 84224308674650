.dealList{
  .btnContainer{
    width: 50%;
  }
  .dealDetailTitle{
    width: 50%;
    transform: translate(-50%);
  }
  ion-button {
  height: 45px;
}
.title{
  --padding-start:0px;
  --padding-end:0px;
  ion-label{
    --color:var(--primary-title-color)
  }
}

}
.filterModal {
  .filterHeader {
      --padding-start: none;
  }
  ion-grid {
      height: 100%;

      ion-row {
          height: 100%;

          ion-col {
              height: calc(100% - 95px);

              ion-list {
                  ion-item {
                      --inner-padding-end: 0px;

                      ion-label {
                          width: 100%;
                          text-align: start;
                      }

                      --border-color:#ECECEC;
                  }

                  .filterCategoryItem {
                      --background: #F5F5F5
                  }
              }
          }

          .subCategory {
              position: fixed;
              z-index: 1;
              background: #F5F5F5;
              overflow: scroll;
              padding-bottom: 90px;

              ion-button {
                  width: 100%;
                  height: 100%;
                  --border-radius: 0px;
                }
          }
          .filterSubcategory {
              z-index: 1;
              position: fixed;
              right: 0;
              overflow: scroll;
              background: white;
              padding-bottom: 90px;
              
              ion-checkbox::part(container) {
                  border-radius: 2px;
              }
          }
      }
  }
}
ion-footer {
  z-index: 10;
  display: flex;

  ion-button {
      width: 100%;
      height: 45px;
      --border-radius: none !important;
      border-radius:none !important;
  }
}
ion-footer:before{
  height: 0px !important;
}
.content{
  .contentGrid {
      min-height: 88vh;
    }
  .contentGridMobile {
    min-height: 79vh;
  }
}