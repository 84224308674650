.CountryCodeModal {
  --width: 90%;
  --max-width: 500px;
  --max-height: 800px;
    padding: 10px;
  --border-radius: 10px;
  --box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);

  .closeIcon {
    margin-right: 10px;
    font-size: 20px;
    cursor: pointer;
  }

  .balanceCards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  ion-searchbar {
    padding-top: 0px;
    --border-radius: 10px;
  }

  .toolBar{
    padding-top: 10px;
  }

  .countryItem{
      
      .countryFlags{
        padding: 0px;
        margin: 10px;
        margin-right: 20px;
        font-size: 20px;
      }
  }
}

.CountryCodeModal::part(content) {
  top: 10%;
  right: auto;
}
