.shareHeader {
  --padding-start: none;
  --min-height: 70px;
  .imgContainer {
    width: 50px;
    height: 40px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      padding: 5px;
      margin-top: 5px;
      border-radius: 5px;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    }
  }
  .product {
    .productTitle {
      margin-bottom: 3px;
    }
    ion-label {
      max-width: 200px;
    }
  }
}
.content {
  img {
    margin: 0 10px;
    width: 60px;
    height: 60px;
  }
  .more {
    span {
      display: flex;
      justify-content: center;
      background: rgb(210, 210, 210);
      color: white;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      margin: 5px;
      font-size: 30px;
    }
  }
}

@media only screen and (max-width: 520px) {
  .modal {
    --max-width: 520px;
    --border-radius: 10px 10px 0px 0px;
    --height: 330px;
  }
}
@media only screen and (min-width: 520px) {
  .modal {
    --max-width: 420px;
  }
  ion-modal::part(content) {
    position: absolute !important;
    bottom: 0px;
    right: 0px;
    --height: 100%;
  }
  .moreContainer {
    display: none;
  }
}
