
.cashbackCard {
    margin: 16px;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    .merchantIcon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #ff9f00;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: bold;
        color: white;
    }
    
    .cashbackCard h2 {
        margin: 0;
        font-size: 18px;
        font-weight: bold;
    }
    
    .cashbackCard p {
        margin: 4px 0 0;
        font-size: 14px;
    }
    
    .amountCol {
        text-align: right;
    }
    
    .amountCol ion-icon {
        font-size: 20px;
        margin-right: 4px;
        vertical-align: middle;
    }
    
    .amountCol h2 {
        display: inline;
        vertical-align: middle;
    }
    
    .pascalCase{
        text-transform: capitalize;
    }

    .logo{
        width: 50px;
    }
}


