.dealListCardContainer {
  ion-card {
    white-space: break-spaces;
    height: fit-content;
    width: 100%;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);

    ion-img {
      padding: 8px;
      height: 140px;
    }

    ion-card-title {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .height18{
    height: 18px;
  }

  .dealPrice {
    text-decoration: line-through;
  }
}

.productImg {
  height: 150px;
  padding: 8px;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: 50% 50%;
  }
}