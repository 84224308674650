.shoppingDetail {

  .productDetailTitle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  ion-content {
      .swiper {
        padding-bottom: 30px;

        ion-card {
          width: 100%;
          border-radius: 8px;
          height: 208px;
          img {
            object-fit: contain;
            height: 100%;
          }
        }
      }
  }

  ion-card-content,
  ion-toolbar {
    .offerPrice {
      font-size: 20px;
    }

    .originalPrice {
      text-decoration: line-through;
    }
  }

  ion-segment-button{
    --color: var(--primary-tabs-text-color);
    --color-checked: var(--primary-tabs-activeTab-color);
    --indicator-height: 3px;
}



}
.content{
  .contentGrid{
    min-height: calc(100% - 65px);
  }
}

ion-segment{
  justify-content: start;
}

@media screen and (min-width: 768px) {
  .swiper {
    ion-card {
      height: 400px !important;
    }
  }
}