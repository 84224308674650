.shoppingHome {
  .searchBarContainer {
    padding: 10px;
    width: 100%;
    ion-searchbar {
      border-radius: 5px;
      --box-shadow: none;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
      padding: 3px;
      input {
        padding: 6px 30px 6px 32px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      input::placeholder {
        font-size: 14px;
      }
      ion-icon {
        left: 5px;
      }
    }
  }
}
.content {
  .contentGrid {
    min-height: calc(100% - 40px);
  }
}

.logoProfileBox {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 3px;
  .mshopLogo {
    display: flex;
    justify-content: center;
    width: 100%;
    img {
      height: var(--logo-height);
      width: var(--logo-width);
      margin-right: -40px;
    }
  }
}

.popOver {
  --width: 200px;
}
