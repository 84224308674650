.cashBack {
  .pageTitle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
  }
  .cashBackList {
    margin-bottom: 150px;
    ion-item {
      --padding-start: 0;
      --padding-end: 0;
      --inner-padding-end: 0;

      .contentContainer {
        width: 100%;
        margin: 10px;

        ion-grid {
          border: 1px solid #ececec;
          border-radius: 5px;

          ion-row {
            border-top: 1px solid #ececec;
          }

          ion-row:first-child {
            border: none;
          }

          ion-col {
            border-left: 1px solid #ececec;
            text-transform: capitalize;
          }

          ion-col:first-child {
            border: none;
          }
        }

        .balanceAmount {
          display: flex;
          align-items: center;
          gap: 5px;
          font-size: 10px;
          margin-left: 5px;
        }

        ion-icon {
          font-size: 15px;
        }

        .tableTitle {
          background: #ececec;

          h6 {
            padding: 10px 5px;
            margin-left: 5px !important;
            margin: 0;
          }
        }
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    ion-button {
      --padding-start: 10px;
      --padding-end: 10px;
    }
  }

  .pagination span {
    margin: 0 10px;
  }

  .loadMoreBtn {
    width: 95%;
    margin-left: 15px;
    // margin-right: 15px;
  }

  .balanceCards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .noTransaction {
    text-align: center;
    margin-top: 30px;
  }
}

.newUser,
.existingUser {
  color: var(--ion-color-primary);
}
