.couponListCard{
  .productImg {
      height: 40px;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: 50% 50%;
      }
    }
}