.headerLogoPopover {
  ion-button {
    --background: transparent;
    --color: black;
    --box-shadow: none;
    height: 50px;
    margin: 0px;
  }

  ion-button:hover {
    --color: var(--ion-color-primary);
  }
  .logoProfileBox {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 3px;

    .mshopLogoLogin {
      display: flex;
      justify-content: center;
      width: 100%;
      img {
        height: var(--logo-height);
        width: var(--logo-width);
        margin-right: -40px;
      }
    }
    .mshopLogo {
      display: flex;
      justify-content: center;
      width: 100%;
      img {
        height: var(--logo-height);
        width: var(--logo-width);
      }
    }
  }
}
.popOver {
  --width: 200px;
}
