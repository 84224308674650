.couponDetail {
  .couponDetailTitle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  ion-card {
    border-radius: 20px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);

    ion-img {
      height: 50px;
    }

    ion-text {
      height: 33px;
      text-transform: uppercase;
      padding: 9px 16px;
      font-size: 12px;
      border: 1px dashed #B5B5B5;
      border-radius: 3px;
      font-weight: 400;
    }

    ion-button{
      --border-radius:10px;
    }
  }



}
.content{
  .contentGrid{
    min-height: calc(100% - 63px);
  }
}

.productImg{
  height: 50px;
  padding: 8px;
  img{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: 50% 50%;
  }
}