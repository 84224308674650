.couponHome {
  .storeName {
    font-size: 12px;
    display: inline-block;
    padding: 6px 0 0 0;
    margin: 0 0 0 16px;
    border-bottom: 1px solid var(--primary-title-color);
    padding-bottom: 5px;
  }

  ion-card {
    height: 180px;
    width: 180px;
    white-space: break-spaces;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);

    ion-text {
      height: 33px;
      text-transform: uppercase;
      padding: 9px 2px;
      font-size: 12px;
      border: 1px dashed #b5b5b5;
      border-radius: 3px;
      font-weight: 400;
    }
  }
  .custom_title {
    padding: 0;
    margin: 0;
    margin-left: 10px;
  }
}
.content {
  .contentGrid {
    min-height: calc(100% - 32px);
  }
}

.mshopLogo {
  display: flex;
  justify-content: center;
  img{
      height: var(--logo-height);
      width: var(--logo-width);
      margin-top: 16px;
      margin-bottom: 3px;
  }
}
