.product {
  display: inline-block;

  ion-card {
    width: 180px;
    min-height: 245px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);

    ion-img {
      padding: 8px;
      height: 140px;
    }

    .productSectionProductPrice {
      text-decoration: line-through;
    }

    ion-text {
      span {
        display: block;
      }
    }
  }
}

.productSectionCardContainer {
  height: 265px;

  .productSectionImgContainer {
    height: 150px;
  }

  .productSectionContentContainer {
    ion-card-header{
      height: 40px;
    }
    // background-color: #f7f7f7;
    height: 140px;
  }
}

.productSubtitle{
  height: 25px;
}
.productImg{
  height: 150px;
  padding: 8px;
  img{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: 50% 50%;
  }
}