.tabHome{
    border-bottom:1px solid rgb(220, 220, 220);
    .backIcon{
        width: 20px;
        height: 20px;
        margin-top: 15px;
    }
    ion-segment-button{
        --color: var(--primary-tabs-text-color);
        --color-checked: var(--primary-tabs-activeTab-color);
        --indicator-height: 3px;
    }
    .mshopLogo{
        display: flex;
        justify-content: center;
        img{
            height: 20px;
            width: 66px;
            margin-top: 16px;
        }
    }
}