.productSpecs {
  ion-item {
    display: flex;
    flex-wrap: wrap;

    ion-text {
      flex: 1;

    }

  }
}