.bannerCarousel {
  .swiper{
   padding-bottom: 25px;
   ion-card{
    width: 100%;
    height: 100%;
    max-height: fit-content;
    img{
      display: flex;
      object-fit: cover;
      width: 100% !important;
      height: 100%;
      }
  }
  }
}
