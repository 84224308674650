.login {
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f3f4f6;
    padding: 15px;
    margin: 0px;
  }

  .loginCard {
    width: 100%;
    max-width: 540px;
    padding: 24px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .logo {
    cursor: pointer;
    width: 150px;
  }
  .logoContainer {
    display: flex;
    justify-content: center;
    // margin-bottom: 24px;
    padding: 5px !important;
  }

  .logo {
    font-size: 32px;
    font-weight: bold;
    margin: 0;

    .nexus-logo-x {
      color: #f97316;
    }
  }

  .subLogo {
    color: #6b7280;
    margin: 4px 0 0;
  }

  .loginForm {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .inputGroup {
    display: flex;
    flex-direction: column;
  }

  .label {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 10px;
  }

  .errorLabel {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }

  .input {
    width: 100%;
    padding: 3px 5px !important;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    font-size: 16px;

    &:focus {
      outline: none;
      border-color: #2563eb;
      box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.2);
    }
  }

  .combo{
    display: flex;
    align-items: center;
    gap: 5px;
    height: 50px;
    ion-button{
      height: 50px;
      padding-left: 10px;
      padding-right: 10px;
      .countryFlags {
          margin-right: 5px;
          font-size: 20px;
        }
    }
  }

  .button {
    width: 100%;
    padding: 10px;
    background-color: #1f2937;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: #374151;
    }
  }

  .forgotPassword {
    text-align: center;
    margin-top: 16px;
  }

  .link {
    color: #2563eb;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .otpInput {
    margin: auto;
    text-align: center;
  }
}
