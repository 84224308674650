.circleScrollbar {
  .circleCategoryCarouselContainer {
    display: flex;
    justify-content: space-between;

    ion-button {
      --padding-bottom: 0px;
      --padding-end: 0px;
      --padding-start: 0px;
      --padding-top: 0px;
      background: transparent;
      border-radius: 50%;
      padding: 0px;
      margin: 4px auto;
      width: 60px;
      height: 60px;
    }

    .circleCarouselItemContainer {
      margin: 10px;
      display: flex;
      flex-direction: column;
    }
    .activeTab{
      border: 4px solid var(--special-button-active-color);
      transition: 0.2s ease;
    }
  }

  h6 {
    font-size: 12px;
  }
}