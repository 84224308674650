.earningRates {
  margin-bottom: 150px;
  ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-end: 0;

    .contentContainer {
      width: 100%;
      margin: 10px;
      ion-grid {
        border: 1px solid #ececec;
        border-radius: 5px;
        ion-row {
          border-top: 1px solid #ececec;
        }
        ion-row:first-child {
          border: none;
        }
        ion-col {
          border-left: 1px solid #ececec;
        }
        ion-col:first-child {
          border: none;
        }
      }
      .tableTitle{
        background: #ececec;
        h6 {
          padding: 10px 5px;
          margin: 0;
        }
      }
    }
  }
}

.newUser, .existingUser{
  color: var(--ion-color-primary);
}