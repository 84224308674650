.productGridItem {
  ion-card {
    white-space: break-spaces;
    height: 255px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);

    ion-img {
      padding: 8px;
      height: 140px;
    }

    .title {
      min-height: 33px;
    }

    .itemDetail {

      @media (max-width: 415px) {
        min-height: 130px;
      }
       .productListProductPrice {
            text-decoration: line-through;
        }

      .price {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        color: var(--ion-color-step-850, #262626);
      }
    }
  }
}

.productImg{
  height: 150px;
  padding: 8px;
  img{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: 50% 50%;
  }
}