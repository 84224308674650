.balanceCards {
  min-width: 230px;
  @media (max-width: 430px) {
    width: 80%;
  }

  .balanceCard {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .balanceHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
  }

  .balanceAmount {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .amount {
    font-size: 24px;
    font-weight: bold;
  }

  ion-icon {
    font-size: 24px;
  }
}
